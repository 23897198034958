import DropDownMenu_LinesMenuButtonSkinComponent from '../components/DropDownMenu/viewer/skinComps/LinesMenuButton/LinesMenuButtonSkin.skin';
import DropDownMenu_LinesMenuButtonSkinController from '../components/DropDownMenu/viewer/DropDownMenu.controller';


const DropDownMenu_LinesMenuButtonSkin = {
  component: DropDownMenu_LinesMenuButtonSkinComponent,
  controller: DropDownMenu_LinesMenuButtonSkinController
};


export const components = {
  ['DropDownMenu_LinesMenuButtonSkin']: DropDownMenu_LinesMenuButtonSkin
};

