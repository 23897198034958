import * as React from 'react';
import { MenuButtonProps } from '../../../MenuButton.types';
import BaseButton from './BaseButton';
import skinsStyle from './styles/LinesMenuButtonNSkin.scss';

const LinesMenuButtonNSkin: React.FC<MenuButtonProps> = props => {
  return (
    <BaseButton
      {...props}
      skinsStyle={skinsStyle}
      skin="LinesMenuButtonNSkin"
    />
  );
};

export default LinesMenuButtonNSkin;
