import * as React from 'react';
import type {
  IDropDownMenuProps,
  IDropDownMenuImperativeActions,
} from '../../../DropDownMenu.types';
import LinesMenuButtonNSkin from '../../../../MenuButton/viewer/skinComps/BaseButton/LinesMenuButtonNSkin';
import LinesMenuButton from './LinesMenuButton';
import styles from './LinesMenuButtonSkin.scss';

const LinesMenuButtonSkin: React.ForwardRefRenderFunction<
  IDropDownMenuImperativeActions,
  IDropDownMenuProps
> = (props, ref) => {
  return (
    <LinesMenuButton
      {...props}
      ref={ref}
      styles={styles}
      Button={LinesMenuButtonNSkin}
    />
  );
};

export default React.forwardRef(LinesMenuButtonSkin);
